import React from "react"
import Layout from "../components/Layout"

import IntroBanner from "../components/Sections/IntroBanner"
import OnlineStores from "../components/Sections/OnlineStores"
import Features from "../components/Sections/Features"
import GradientBackground from "../components/Sections/GradientBackground"
import Integrations from "../components/Sections/Integrations"
import HowReferralCandyWorks from "../components/Sections/HowReferralCandyWorks"
import Testimonials from "../components/Sections/Testimonials"
import AdditionalFeatures from "../components/Sections/AdditionalFeatures"
import CustomerSuccess from "../components/Sections/CustomerSuccess"

export default () => {
  return (
    <Layout>
      <IntroBanner />
      <OnlineStores />
      <Features />
      <GradientBackground>
        <Integrations />
        <HowReferralCandyWorks />
      </GradientBackground>
      <Testimonials />
      <AdditionalFeatures />
      <CustomerSuccess />
    </Layout>
  )
}
