import React, { PureComponent } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import classnames from "classnames"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

import Grid from "../../Grid"
import Button from "../../Button"

import css from "./index.module.scss"

const SECTIONS = [
  {
    index: 0,
    key: "secretlab",
    quote:
      "ReferralCandy fulfilled our multiple-store needs, in terms of functionality, flexibility, and incredible customer support.",
    cite: "Ian Ang",
    position: "Co-founder, Secretlab",
  },
  {
    index: 1,
    key: "criticalpass",
    quote:
      "We have a few power users of our referral program, who have driven us a ton of referrals, and they’ve made a ton of money with it.",
    cite: "Nathan Kleiner",
    position: "CEO, Critical Pass",
    href: "/case-studies/critical-pass",
  },
  {
    index: 2,
    key: "riffraff",
    quote:
      "Our referral program with Referral Candy is an integral part of our marketing portfolio and is responsible for 20-35% of our sales each month.",
    cite: "Emma Kruger",
    position: "CEO, Riff Raff & Co",
    href: "/case-studies/riff-raff",
  },
  {
    index: 3,
    key: "magicalbutter",
    quote:
      "I love that you can see all the information you really need – who referred, who was the referrer, the amount",
    cite: "Chris Whitener",
    position: "Sales Manager, Magical Butter",
    href: "/case-studies/magical-butter",
  },
  {
    index: 4,
    key: "ztylus",
    quote:
      "The ease of setup was nice — the fact that everything was taken for us in the backend. Apart from some manual stuff for the returns, everything else runs pretty smoothly.",
    cite: "Thomas Chen",
    position: "COO, Ztylus",
    href: "/case-studies/ztylus",
  },
]

const TESTIMONIAL_CLASSNAMES = {
  enter: css["in"],
  enterActive: css.in_active,
  exit: css["out"],
  exitActive: css.out_active,
}
const ANIMATION_TOTAL_TIME = 1250

const DEFAULT_SECTION = SECTIONS[0]
const ROTATE_TIME = 20000

const extractSectionFromHash = () => {
  const match =
    window.location.hash && window.location.hash.match(/^#testimonial-(.+)$/)
  return match
    ? SECTIONS.find((section) => section.key === match[1])
    : DEFAULT_SECTION
}

class Testimonials extends PureComponent {
  state = {
    section: DEFAULT_SECTION,
    // Dynamic property represents whether or not its server side rendered or not
    // When its server side rendered, we want all the content to be available (SEO reasons)
    // When its loaded, it's hidden behind a carousel click
    dynamic: false,
  }
  nextSectionTimeout = null

  onSectionClick = (section) => (e) => {
    e.preventDefault()
    this.setState({ section: section })
    this.scheduleNextTestimonial()
  }

  scheduleNextTestimonial = () => {
    clearTimeout(this.nextSectionTimeout)
    this.nextSectionTimeout = setTimeout(() => {
      const index = SECTIONS.indexOf(this.state.section)
      const nextIndex = index >= SECTIONS.length - 1 ? 0 : index + 1
      this.setState({ section: SECTIONS[nextIndex] })
      this.scheduleNextTestimonial()
    }, ROTATE_TIME)
  }

  waitForScrollIntoView = () => {
    if (!this.navRef) return
    const target = this.navRef
    const observer = new IntersectionObserver(
      (entries) => {
        const visible = entries[0] && entries[0].isIntersecting
        if (visible) {
          this.scheduleNextTestimonial()
        } else {
          clearTimeout(this.nextSectionTimeout)
        }
      },
      {
        threshold: 0.25,
      }
    )
    observer.observe(target)

    this.clearScrollIntoView = () => observer.unobserve(target)
  }

  componentDidMount() {
    const section = extractSectionFromHash()
    this.setState({
      section: section,
      dynamic: true,
    })
    this.waitForScrollIntoView()
  }

  componentWillUnmount() {
    clearTimeout(this.nextSectionTimeout)
    if (this.clearScrollIntoView) this.clearScrollIntoView()
  }

  render() {
    const activeSection = this.state.section || DEFAULT_SECTION
    const previousSection = SECTIONS[activeSection.index - 1] || activeSection
    const nextSection = SECTIONS[activeSection.index + 1] || activeSection
    const renderedSections = this.state.dynamic ? [activeSection] : SECTIONS
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <ul className={css.nav} ref={(ref) => (this.navRef = ref)}>
          {SECTIONS.map((section) => (
            <li key={section.key}>
              <a
                href={`#testimonial-${section.key}`}
                className={activeSection === section ? css.active : ""}
                onClick={this.onSectionClick(section)}
                aria-label={section.key}
              >
                <Img
                  alt={`${section.key} section`}
                  fluid={data[`logo_${section.key}`].childImageSharp.fluid}
                />
              </a>
            </li>
          ))}
        </ul>
        <div className={css.middle_section}>
          <FaAngleLeft
            className={css.arrow}
            onClick={this.onSectionClick(previousSection)}
          />
          <TransitionGroup className={css.contents}>
            {renderedSections.map((section) => {
              const key = section.key
              return (
                <CSSTransition
                  classNames={TESTIMONIAL_CLASSNAMES}
                  timeout={ANIMATION_TOTAL_TIME}
                  key={key}
                >
                  <div
                    className={classnames(
                      css.content,
                      css[`testimonial-${key}`]
                    )}
                    id={`testimonial-${key}`}
                  >
                    <div className={css.images}>
                      <div className={css.picture}>
                        <Img
                          fluid={data[`picture_${key}`].childImageSharp.fluid}
                          className={css.gatsby_image}
                        />
                      </div>
                    </div>
                    <blockquote className={css.quote}>
                      <p className={css.quote_text}>{section.quote}</p>
                      <footer>
                        <div className={css.avatar_container}>
                          <Img
                            fluid={data[`avatar_${key}`].childImageSharp.fluid}
                          />
                        </div>
                        <cite>
                          <p>{section.cite}</p>
                          <p>{section.position}</p>
                        </cite>
                      </footer>
                      {section.href && (
                        <Button
                          className={css.quote_button}
                          purpose="secondary"
                          href={section.href}
                          id={`signup-testimonials-${key}`}
                          target="_blank"
                        >
                          Read Case Study
                        </Button>
                      )}
                    </blockquote>
                  </div>
                </CSSTransition>
              )
            })}
          </TransitionGroup>
          <FaAngleRight
            className={css.arrow}
            onClick={this.onSectionClick(nextSection)}
          />
        </div>
        <ul className={css.bottom_nav} ref={(ref) => (this.navRef = ref)}>
          {SECTIONS.map((section) => (
            <li key={section.key}>
              <a
                href={`#testimonial-${section.key}`}
                className={activeSection === section ? css.active : ""}
                onClick={this.onSectionClick(section)}
                aria-label={section.key}
              >
                <div className={css.blob} />
              </a>
            </li>
          ))}
        </ul>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    logo_criticalpass: file(
      relativePath: {
        eq: "images/store_logos/opaque_logos/Referralcandy-customer-referral-program-software-criticalpass-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo_riffraff: file(
      relativePath: {
        eq: "images/store_logos/opaque_logos/Referralcandy-customer-referral-program-software-riffraff-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo_magicalbutter: file(
      relativePath: {
        eq: "images/store_logos/opaque_logos/Referralcandy-customer-referral-program-software-magicalbutter-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo_ztylus: file(
      relativePath: {
        eq: "images/store_logos/opaque_logos/Referralcandy-customer-referral-program-software-ztylus-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_criticalpass: file(
      relativePath: { eq: "images/testimonials/picture-critical-pass.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_riffraff: file(
      relativePath: { eq: "images/testimonials/picture-riff-raff.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_magicalbutter: file(
      relativePath: { eq: "images/testimonials/picture-magical-butter.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_ztylus: file(
      relativePath: { eq: "images/testimonials/picture-ztylus.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_criticalpass: file(
      relativePath: { eq: "images/testimonials/avatar-critical-pass.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_riffraff: file(
      relativePath: { eq: "images/testimonials/avatar-riff-raff.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_magicalbutter: file(
      relativePath: { eq: "images/testimonials/avatar-magical-butter.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_ztylus: file(
      relativePath: { eq: "images/testimonials/avatar-ztylus.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo_secretlab: file(
      relativePath: { eq: "images/store_logos/logo-enterprise-secretlab.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    picture_secretlab: file(
      relativePath: { eq: "images/testimonials/picture-secretlab.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    avatar_secretlab: file(
      relativePath: { eq: "images/testimonials/avatar-secretlab.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Testimonials data={data} {...props} />}
  />
)
