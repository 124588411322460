import React, { PureComponent } from "react"
import css from "./index.module.scss"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Grid from "../../Grid"

class OnlineStores extends PureComponent {
  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <h3 className={css.title}>
          We’ve run referral programs for over{" "}
          <span className={css.underline}>30,000</span> online stores and
          counting
        </h3>
        <ul className={css.store_logos}>
          <li>
            <Img fixed={data["logo_sennheiser"].childImageSharp.fixed} />
          </li>
          <li>
            <Img fixed={data["logo_threadbeast"].childImageSharp.fixed} />
          </li>
          <li>
            <Img fixed={data["logo_feed"].childImageSharp.fixed} />
          </li>
          <li>
            <Img fixed={data["logo_flattummy"].childImageSharp.fixed} />
          </li>
        </ul>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    logo_threadbeast: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy-customer-referral-program-software-threadbeast-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fixed(width: 100, height: 27) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_feed: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy-customer-referral-program-software-feed-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fixed(width: 80, height: 23) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_flattummy: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy-customer-referral-program-software-flat-tummy-tea-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fixed(width: 134, height: 16) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_sennheiser: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy-customer-referral-program-software-sennheiser-uses-referralcandy-for-referral-marketing.png"
      }
    ) {
      childImageSharp {
        fixed(width: 152, height: 15) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <OnlineStores data={data} {...props} />}
  />
)
