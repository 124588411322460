import React from "react"
import { FaArrowRight } from "react-icons/fa"
import classnames from "classnames"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"

import SignupLink from "../../SignupLink"
import Grid from "../../Grid"
import Button from "../../Button"

const query = graphql`
  query {
    banner: file(relativePath: { eq: "images/introbanner.png" }) {
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const IntroBanner = () => {
  const { banner } = useStaticQuery(query)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <div className={classnames("announcekit-widget-main")} />
        <h1>Grow your sales through word-of-mouth marketing</h1>
        <p className={fontType.b1}>
          Reward your customers for referrals and watch the sales and shares
          roll in.
        </p>
        <SignupLink el={Button} purpose="primary" id="signup-people-queue">
          Try Free for 30 Days &nbsp;
          <FaArrowRight />
        </SignupLink>
        <p className={fontType.b4}>No contract. Cancel anytime</p>
      </div>
      <div className={css.image}>
        <Img
          fixed={banner.childImageSharp.fixed}
          alt="Referralcandy referral marketing program software - referral marketing platform refer a friend software"
        />
      </div>
    </Grid>
  )
}

export default IntroBanner
