import React from "react"
import Img from "gatsby-image"
import classnames from "classnames"
import { useStaticQuery, graphql } from "gatsby"

import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"
import bags from "../../../assets/images/icn-bags.svg"
import Grid from "../../Grid"

const Features = () => {
  const data = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2 className={css.title}>
        Complete your marketing strategy
        <br />
        with a referral program
      </h2>
      <ul className={css.items}>
        <li>
          <img
            alt="Running Bag Icon"
            src={bags}
            style={{ height: "100px", marginBottom: "1rem" }} // manual img rather than Img due to svg
          />
          <div className={css.feature_header}>
            <p className={classnames(fontType.b1, css.header)}>
              A referral program
              <br />
              that runs itself
            </p>
          </div>
          <div className={css.feature_body}>
            <p className={css.body}>
              Rewards are automatically paid out. ReferralCandy frees up your
              time so you can run your business.
            </p>
          </div>
        </li>
        <li>
          <Img
            style={{
              marginBottom: "1rem",
            }}
            fixed={data["analytics"].childImageSharp.fixed}
            alt="Analytics Icon"
          />
          <div className={css.feature_header}>
            <p className={classnames(fontType.b1, css.header)}>
              See your results
              <br />
              at a glance
            </p>
          </div>
          <div className={css.feature_body}>
            <p className={css.body}>
              ReferralCandy tracks your referrals to help you understand how
              much additional sales you are making.
            </p>
          </div>
        </li>
        <li>
          <Img
            style={{
              marginBottom: "1rem",
            }}
            fixed={data["referralProgram"].childImageSharp.fixed}
            alt="Referral Program Icon"
          />
          <div className={css.feature_header}>
            <p className={classnames(fontType.b1, css.header)}>
              Prevent fraud
              <br />
              to increase your ROI
            </p>
          </div>
          <div className={css.feature_body}>
            <p className={css.body}>
              ReferralCandy helps you deal with fraud and spam so that you can
              maximize your returns.
            </p>
          </div>
        </li>
      </ul>
    </Grid>
  )
}

const query = graphql`
  query {
    analytics: file(
      relativePath: {
        eq: "images/Referralcandy-dashboards-referral-marketing-program-software-referral-marketing-analytics-data-superstar-referrers.png"
      }
    ) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    referralProgram: file(
      relativePath: {
        eq: "images/Referralcandy-referral-marketing-program-software-referral-marketing-automation.png"
      }
    ) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    stickyEmails: file(
      relativePath: {
        eq: "images/Referralcandy-referral-marketing-program-software-referral-marketing-emails-roi.png"
      }
    ) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default Features
