import React from "react"
import SignupLink from "../../SignupLink"
import Button from "../../Button"
import VideoSection from "../VideoSection"
import { FaArrowRight } from "react-icons/fa"

export default () => (
  <VideoSection
    title="How ReferralCandy works"
    vimeoSrc="https://player.vimeo.com/video/327218283?title=0&byline=0&portrait=0"
    ctaButton={
      <SignupLink el={Button} purpose="primary">
        Try it Now &nbsp; <FaArrowRight />
      </SignupLink>
    }
  />
)
