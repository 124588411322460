import React, { PureComponent } from "react"
import css from "./index.module.scss"

import Grid from "../../Grid"

import { onAppear } from "../../../helpers/useIntersectionObserver"

class VideoSection extends PureComponent {
  state = {
    inView: false,
  }

  bindLoadIframe() {
    if (!this.containerRef) return
    this.unbindLoadIframe = onAppear([this.containerRef], (entry) => {
      this.setState({
        inView: true,
      })
    })
  }

  componentDidMount() {
    this.bindLoadIframe()
  }

  componentWillUnmount() {
    if (this.unbindLoadIframe) this.unbindLoadIframe()
  }

  render() {
    const { title, vimeoSrc, ctaButton, ...rest } = this.props
    return (
      <Grid className={css.container} {...rest}>
        <div className={css.video} ref={(ref) => (this.containerRef = ref)}>
          <h2>{title}</h2>
          <div className={css.vcontainer}>
            {this.state.inView ? (
              <iframe
                title={title}
                src={vimeoSrc}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            ) : null}
          </div>
          <p>Want to try it yourself?</p>
          {ctaButton}
        </div>
      </Grid>
    )
  }
}

export default VideoSection
