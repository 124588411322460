import React, { PureComponent } from "react"
import css from "./index.module.scss"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

import INTEGRATION_LISTINGS from "./IntegrationListings"

class Integrations extends PureComponent {
  renderIntegrationListings = (data) => {
    return INTEGRATION_LISTINGS.map((integration, index) => {
      return (
        <div className={css.int_container} key={`integration-${index}`}>
          <div className={css.logo}>
            <Img
              fixed={data[`${integration.logo}`].childImageSharp.fixed}
              alt={integration.alt}
            />
          </div>
          <div className={css.content}>
            <p className={css.name}>{integration.name}</p>
            <p className={css.description}>{integration.description}</p>
          </div>
        </div>
      )
    })
  }

  render() {
    const data = this.props.data

    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.text}>
          <h2>
            ReferralCandy
            <br />
            works with your
            <br />
            marketing stack
          </h2>
          <Button
            className={css.desktop_button}
            href="/features/#integration"
            target="_blank"
          >
            View All Integrations
          </Button>
        </div>
        <div className={css.listings}>
          {this.renderIntegrationListings(data)}
        </div>
        <Button
          purpose="secondary"
          shrinkwrap
          className={css.mobile_button}
          href="/features/#integration"
          target="_blank"
        >
          View All Integrations
        </Button>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    shopify: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-shopify-best-shopify-referral-marketing-app-shopify@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    woocommerce: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-woocommerce-best-woocommerce-referral-marketing-app-woocommerce@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 35, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    bigcommerce: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-bigcommerce-best-bigcommerce-referral-marketing-app-bigcommerce@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    magento: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-magento-best-magento-referral-marketing-app-magento@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 35, height: 41) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    mailchimp: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-shopify-best-mailchimp-referral-marketing-app-small-business-referrals-mailchimp@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 29, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    ga: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-analytics-google-referral-marketing-traffic-data-referral-analytics-ga@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 30, height: 31) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    klaviyo: file(
      relativePath: {
        eq: "images/integration_logos/Referralcandy-integrates-with-klaviyo-best-klaviyo-referral-marketing-app-small-business-referrals-klaviyo@3x.png"
      }
    ) {
      childImageSharp {
        fixed(width: 39, height: 26) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Integrations data={data} {...props} />}
  />
)
