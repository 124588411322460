import React, { PureComponent } from "react"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"

import css from "./index.module.scss"
import fontType from "../../../helpers/fontType.module.scss"

import Button from "../../Button"
import Grid from "../../Grid"

export const Features = () => {
  const data = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2 className={css.title}>
        Stand out with <br />
        ReferralCandy
      </h2>
      <div className={css.content}>
        <div className={css.text}>
          <ul className={css.items}>
            <li>
              <Img
                style={{
                  marginBottom: "1rem",
                }}
                fixed={data["customizeRewards"].childImageSharp.fixed}
                alt="Customize Rewards Icon"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <p className={fontType.b1}>Customize Reward Options</p>
              </div>
              <div className={css.feature_body}>
                <p className={css.body}>
                  Choose from cash rewards, store discounts or custom gifts
                </p>
              </div>
            </li>
            <li>
              <Img
                style={{
                  marginBottom: "1rem",
                }}
                fixed={data["liveChat"].childImageSharp.fixed}
                alt="Live Chat Support Icon"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <p className={fontType.b1}>Live Chat Support</p>
              </div>
              <div className={css.feature_body}>
                <p className={css.body}>
                  Five-star customer success live chat to get you set up and
                  growing
                </p>
              </div>
            </li>
            <li>
              <Img
                style={{
                  marginBottom: "1rem",
                }}
                fixed={data["oneClick"].childImageSharp.fixed}
                alt="One Click Integration Icon"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <p className={fontType.b1}>One-Click Integrations</p>
              </div>
              <div className={css.feature_body}>
                <p className={css.body}>
                  Connects easily to eCommerce and marketing platforms
                </p>
              </div>
            </li>
            <li>
              <Img
                style={{
                  marginBottom: "1rem",
                }}
                fixed={data["dataDashboard"].childImageSharp.fixed}
                alt="Data Dashboard Icon"
                height="300"
                width="300"
              />
              <div className={css.feature_header}>
                <p className={fontType.b1}>Data and Dashboards</p>
              </div>
              <div className={css.feature_body}>
                <p className={css.body}>
                  See analytics and insights like industry benchmarks and top
                  referrers
                </p>
              </div>
            </li>
          </ul>
          <Button el={Link} to="/features">
            View all features
          </Button>
        </div>
        <div className={css.image}>
          <Img
            fixed={data["standOutGirl"].childImageSharp.fixed}
            alt="Stand out girl image"
          />
        </div>
      </div>
    </Grid>
  )
}

class AdditionalFeatures extends PureComponent {
  render() {
    return <Features />
  }
}

const query = graphql`
  query {
    customizeRewards: file(
      relativePath: {
        eq: "images/additional_features/Referralcandy-referral-marketing-program-software-referralcandy-feature-custom-rewards-cash-payout.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    liveChat: file(
      relativePath: {
        eq: "images/additional_features/Referralcandy-referral-marketing-program-software-referralcandy-live-chat-support-customer-success.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    oneClick: file(
      relativePath: {
        eq: "images/additional_features/Referralcandy-referral-marketing-program-software-referralcandy-one-click-set-up-referral.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    dataDashboard: file(
      relativePath: {
        eq: "images/additional_features/Referralcandy-referral-dashboards-referral-marketing-traffic-data-referral-analytics.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    standOutGirl: file(relativePath: { eq: "images/standOutGirl.png" }) {
      childImageSharp {
        fixed(height: 350) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default AdditionalFeatures
